
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGpzLWpvZGEvY29yZQ_ from '@js-joda/core';
import * as _ref_ZmlsZS1zYXZlcg_ from 'file-saver';
import * as _ref_anN6aXA_ from 'jszip';
import { instantiate } from './composeApp.uninstantiated.mjs';

const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    '@js-joda/core': _ref_QGpzLWpvZGEvY29yZQ_,
    'file-saver': _ref_ZmlsZS1zYXZlcg_,
    'jszip': _ref_anN6aXA_
})).exports;

export default new Proxy(exports, {
    _shownError: false,
    get(target, prop) {
        if (!this._shownError) {
            this._shownError = true;
            throw new Error("Do not use default import. Use the corresponding named import instead.")
        }
    }
});
export const {
    _initialize,
    memory
} = exports;

